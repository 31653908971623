@import "src/styles/global";

.container {
  max-width: 1160px;
  height: 100%;
  margin: auto;
  padding: 0;
  @include laptop {
    max-width: 95%;
  }
  &_small {
    max-width: 1150px;
    height: 100%;
    margin: auto;
    padding: 0;
    @include laptop {
      max-width: 95%;
      overflow: visible;
    }
  }
}

@import "styles/variables.scss";

.wr {
  margin-bottom: 131px;
  @include mobile {
    margin-bottom: 45px;
  }
  & > h2 {
    margin-bottom: 60px;
    @include mobile {
      margin-bottom: 20px;
    }
  }
  @include mobile {
    position: relative;
    &::after {
      width: 758px;
      height: 693px;
      position: absolute;
      content: "";
      bottom: -30%;
      right: 4%;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("/assets/smi/blot.png");
      z-index: -2;
    }
  }
}

.slider {
  overflow: visible;
  & > div > div {
    max-width: 75%;
  }
}

.card {
  &_img {
    border-radius: 25px;
    max-width: 100%;
    margin-bottom: 20px;
    @include mobile {
      margin-bottom: 18px;
    }
  }
  &_footer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    @include mobile {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @include mulishBold;
    & > h3 {
      color: #262626;
      font-size: 16px;
    }
    & > a {
      padding: 10px 15px 12px;
      color: #000;
      font-size: 17.5px;
      transition: transform 0.3s;
      border-radius: 26px;
      border: 2px solid #000;
      &:hover {
        transform: scale(1.05);
      }
      @include mobile {
        padding: 8px 13px 11px;
        font-size: 15.4px;
      }
    }
  }
}

@import "src/styles/global";

.wrapper {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 70px;
  position: relative;
  @include tablet {
    gap: 30px;
  }


  &::after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    left: 150px;
    top: -350px;
    width: 1706px;
    height: 1203px;
    background-image: url("../../assets/blots/bg2.png");
    z-index: -4;
  }
  &::before {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    bottom: 200px;
    left: -400px;
    width: 722px;
    height: 646px;
    background-image: url("../../assets/blots/bg3.png");
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  .title {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 52px;
    @include mobile {
      gap: 10px;
    }
    &::after {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      left: -170px;
      top: -250px;
      width: 458px;
      height: 390px;
      background-image: url("../../assets/blots/bg1.png");
      z-index: 0;
      @include mobile {
        left: -150px;
        top: -120px;
        transform: scale(0.55);
        z-index: -1;
      }
    }
    h1 {
      @include dela;
      position: relative;
      font-size: 97.934px;
      font-style: normal;
      font-weight: 400;
      line-height: 54.3%; /* 53.178px */
      text-transform: uppercase;
      text-align: right;
      white-space: nowrap;
      z-index: 2;
      @include laptop {
        font-size: 7.95vw;
        line-height: 35%;
      }
      @include mobile {
        font-size: 7.95vw;
        line-height: 100%;
        z-index: -1;
      }
    }
    a {
      h3 {
        @include dela;
        font-size: 23.508px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        text-decoration: none;
        background-image: linear-gradient(currentColor, currentColor);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 100% 3px;
        transition: background-size 0.5s;
        &:hover {
          background-size: 0% 1.5px;
        }
        @include laptop {
          font-size: 3.08vw;
        }
      }

    }
    &_row2 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 15px;
      h1 {
        z-index: 0;
      }
      @include laptop {
        display: flex;
        flex-wrap: wrap;
        width: fit-content;
        gap: 15px;
        flex-direction: column;
        align-items: flex-end;
      }
      @include mobile {
        gap: 5px;
      }
    }
  }
  .row1 {
    display: flex;
  }
  .row2 {
    display: flex;
    margin-top: 30px;
    @include tablet {
      margin-top: 0;
    }
    h4 {
      @include mulish;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 30px */
      @include tablet {
        font-size: 25px;
      }
      @include mobile {
        font-size: 4.36vw;
      }
    }
    a {
      width: 80%;
      position: relative;
      transition: 0.5s ease;
      animation: pulse 1.5s linear infinite;
      &:hover {
        transform: scale(1.1);
        animation: none;
      }
      @keyframes pulse {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.9);

        }
        100% {
          transform: scale(1);
        }
      }
      @include tablet {
        width: 100%;
      }
      h3{
        @include dela;
        text-align: center;
        font-size: 26.785px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 29.464px */
        text-transform: uppercase;

        position: absolute;
        transform: rotate(9.394deg);
        left: 30%;

        @include tablet {
          left: 0%;
          top: 20%;
          font-size: 20px;
        }
        @include mobile {
          font-size: 14px;
        }
        &::after {
          pointer-events: none;
          content: "";
          display: block;
          position: absolute;
          transform: rotate(-5deg);
          left: -50px;
          top: -80px;
          width: 358px;
          height: 205px;
          background-image: url("../../assets/blots/text1.png");
          z-index: -1;
          @include tablet {
            left: auto;
            right: -40%;
            top: -90px;

            contain: content;
            transform: scale(0.7) rotate(-10deg);
          }
          @include mobile {
            left: auto;
            right: -80%;
            top: -95px;

            contain: content;
            transform: scale(0.55) rotate(-10deg);
          }
        }
      }
    }
  }
  .row3 {
    display: flex;
    //justify-content: center;
    align-items: center;
    gap: 60px;
    position: relative;
    @include tablet {
      flex-direction: column;
    }
    @include mobile {
      gap: 3.59vw;
      align-items: flex-start;
    }
    h2 {
      @include dela;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 38.4px */
      text-transform: uppercase;

      max-width: 530px;
      margin: 20px;
      position: relative;
      transform: rotate(-3.852deg);
      @include mobile {

        max-width: 90%;
      }
      &::after {
        pointer-events: none;
        content: "";
        display: block;
        position: absolute;
        left: -20px;
        top: -30px;
        width: 593px;
        height: 172px;
        background-image: url("../../assets/blots/text2.png");
        z-index: -1;
        transform: rotate(3.852deg);
        @include mobile {
          left: 0;
          top: 70%;
          width: 221px;
          height: 40px;
          transform-origin: center;
          transform: rotate(0);
          background-image: url("../../assets/blots/text9.png");
        }
      }
      @include mobile {
        text-align: start;
        font-size: 5.64vw;
        transform: rotate(0);

        margin: 0;
      }
    }
    &_text {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 450px;
      position: relative;
      @include tablet {
        gap: 3.59vw;
      }
      &::after {
        pointer-events: none;
        content: "";
        display: block;
        position: absolute;
        right: -350px;
        top: 60px;
        width: 393px;
        height: 326px;
        background-image: url("../../assets/blots/bg4.png");
        z-index: -1;
      }
      p {
        @include mulish;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @include mobile {
          font-size: 3.85vw;
        }
      }
      b {
        font-weight: 700;
      }
    }
  }
  video {
    border-radius: 17px;

  }
}

@import "styles/variables.scss";

.footer {
  background-color: #000;
  padding: 50px 0 66px;
  position: relative;
  z-index: 9999;

  @include mobile {
    padding: 23px 0 46px;
    position: relative;
  }
  &_wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    row-gap: 40px;
    column-gap: 10px;
    @include laptop {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
    }
    @include tablet {
      grid-template-columns: 0.7fr 0.7fr 1fr;
      gap: 30px;
    }
    @include mobile {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 25px;
    }
  }
}

.logo {
  & > svg {
    object-fit: contain;
    max-width: 100%;
    @include mobile {
      max-width: 233px;
    }
  }
}

.address {
  transform: translateY(-5px);
  max-width: 75%;
  grid-row: span 2;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @include laptop {
    gap: 10px;
    max-width: 100%;
    transform: none;
  }
  @include mobile {
    grid-row: 3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  & > a {
    color: #6c6c6c;
    @include mulishSemi;
    font-size: 16px;
    line-height: normal;
    @include hoverLinkWhite;
    @include laptop {
      font-size: 1.4vw;
    }
    @include mobile {
      font-size: 11px;
      letter-spacing: -0.22px;
    }
  }
}
.social {
  justify-self: end;
  grid-column: 3;
  display: flex;
  gap: 30px;
  & > a {
    @include hoverLinkWhite;
    &:hover {
      border-color: #fff;
    }
    transition: border-color 0.3s, color 0.3s;
    color: #cbcbcb;
    height: fit-content;
    @include dela;
    font-size: 20px;
    line-height: normal;
    text-transform: uppercase;
    padding-bottom: 3.5px;
    border-bottom: 3px solid transparent;
    @include laptop {
      font-size: 18px;
    }
    @include tablet {
      font-size: 12px;
    }
    @include mobile {
      font-size: 14px;
    }
  }
  @include laptop {
    gap: 20px;
  }
  @include tablet {
    gap: 10px;
  }
  @include mobile {
    grid-column: 1;
    grid-row: 2;
    gap: 23px;
    justify-self: start;
  }
}

.pushkeen {
  grid-column: 3;
  grid-row: 2;
  justify-self: end;
  color: #5c5c5c;
  @include dela;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 120%;
  text-align: right;
  @include hoverLinkWhite;
  @include laptop {
    font-size: 18px;
  }
  @include tablet {
    font-size: 9px;
  }

  @include mobile {
    font-size: 12px;
    grid-column: 1;
    grid-row: 4;
    justify-self: start;
    transform: none;
  }
}

@import "styles/variables.scss";

.wr {
  margin-bottom: 107px;
  @include mobile {
    margin-bottom: 85px;
  }
  & img,
  & svg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  @include mobile {
    & > h2:first-child {
      position: relative;
      &::after {
        width: 317px;
        height: 28px;
        position: absolute;
        content: "";
        bottom: -50%;
        right: 10%;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("/assets/partners/blot1.png");
        z-index: -1;
      }
    }
  }
}

.organizators {
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 70px auto 98px;
  @include laptop {
    max-width: 60%;
  }
  @include mobile {
    margin: 34px auto 61px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 40px;
    justify-items: center;
    align-items: start;
    max-width: 90%;
    gap: 0px;
    & svg {
      height: 26px;
    }
  }

  & > span {
    color: #262626;
    text-align: center;
    @include bebas;
    font-size: 35px;
    line-height: 115%;
    @include laptop {
      font-size: 3vw;
    }
    @include mobile {
      font-size: 20px;
    }
  }
}
.partners {
  margin: 100px auto 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  & > div {
    display: flex;
    flex-direction: row;

    &:first-child {
      & a {
        &:first-child {
          max-width: 120px;
          max-height: 112px;
          mix-blend-mode: darken;
        }
        &:nth-child(3) {
          filter: grayscale(100%);
          transform: translateX(-30px);
        }
      }
      gap: 172px;
      align-items: flex-start;
      @include tablet {
        gap: 30px;
        justify-content: space-between;
      }
      @include mobile {
        gap: 40px;
        max-width: 75%;
      }
    }
    &:last-child {
      gap: 118px;
      align-items: center;
      & > a {
        &:first-child {
          transform: translateX(-50px);
        }
        &:nth-child(2) {
          transform: translateX(-10px);
        }
        &:nth-child(3) {
          transform: translateX(-10px);
          filter: grayscale(100%) opacity(70%);
        }
      }
      @include tablet {
        gap: 30px;
        justify-content: space-between;
      }
    }
  }
  @include laptop {
    margin: 50px auto;
  }
  @include mobile {
    row-gap: 25px;
  }

  &_label {
    color: #262626;
    text-align: center;
    @include mulishBold;
    font-size: 35px;
    line-height: 115%;
    max-width: 70%;
    margin: 0 auto 108px;

    @include laptop {
      font-size: 25px;
    }
    @include mobile {
      font-size: 17px;
      max-width: 80%;
      margin: 0 auto 35px;
      text-align: center;
      position: relative;
    }
    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 45%;
      margin: 0 auto;
      & img {
        filter: grayscale(100%) opacity(70%);
        transition: filter 0.3s;
        &:hover {
          filter: none;
        }
      }
      @include laptop {
        max-width: 70%;
        justify-content: center;
        gap: 10%;
      }
      @include mobile {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 46px;
        justify-items: center;
        max-width: 80%;
      }
    }
  }
}

.partner_link {
  transition: filter 0.3s;
  width: 30%;
  text-align: center;
  filter: grayscale(100%) opacity(55%);
  &:hover {
    filter: none !important;
  }
  @include tablet {
    transform: none !important;
  }
  // @include mobile {
  //   & > img {
  //     max-width: 70%;
  //   }
  // }
}

@import "styles/variables.scss";

.wr {
  padding-bottom: 101px;
  position: relative;
  @include laptop {
    padding-bottom: 40px;
  }
  @include mobile {
    padding-bottom: 20px;
  }
  & > h2 {
    margin-bottom: 50px;
    @include laptop {
      margin-bottom: 30px;
    }
    @include mobile {
      margin-bottom: 15px;
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }
  &::before {
    background-image: url("/assets/bg_blot1.png");
    bottom: 30%;
    left: 65%;
    width: 1059px;
    height: 1097px;
    @include mobile {
      width: 576px;
      height: 597px;
      left: 45%;
      bottom: -15%;
    }
  }
  &::after {
    background-image: url("/assets/bg_blot2.png");
    bottom: 85%;
    right: 60%;
    width: 990px;
    height: 1026px;
    @include mobile {
      background-image: url("/assets/line.png");
      left: -210%;
      bottom: 10%;
      width: 1160px;
      height: 718px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 50px;

  @include mobile {
    gap: 12px;
  }
  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include tablet {
      gap: 16px;
    }
    @include mobile {
      width: 100%;
      grid-template-columns: 1fr;
    }
    & > p {
      color: #262626;
      font-size: 25px;
      line-height: 110%;
      max-width: 80%;
      @include mulishBold;
      @include tablet {
        max-width: 100%;
      }
      @include mobile {
        font-size: 17px;
        line-height: 115%;
      }
      &:last-child {
        max-width: 55%;
        @include laptop {
          max-width: 80%;
        }
        @include tablet {
          max-width: 100%;
        }
        & > br {
          display: none;
          @include laptop {
            display: block;
          }
        }
      }
    }
  }
  & iframe {
    width: 100%;
    @include laptop {
      height: 50vw;
      padding: 3vw;
    }
    @include mobile {
      height: 112vw;
      padding: 3vw;
    }
  }
}

@import "src/styles/global";

.header {
  //padding: 0 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(234, 233, 228, 0.01);

  top: 0;
  width: 100%;
  position: relative;

  overflow: hidden;
  transition: 0.5s;
  z-index: 100;


  @include tablet {
    padding: 0;
  }
  @include mobile {
    display: flex;
    top: 0;
    position: fixed;
    flex-direction: column;
    width: 100%;
    height: 38px;
    flex-shrink: 0;
    background: #eae9e4;
    justify-content: flex-start;
    align-items: start;
    padding: 0;
    transition: 0.5s;
    border-bottom: none;
    filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.25));
  }

  .wrapper {
    width: 100%;
    max-width: 1160px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    @include laptop {
      max-width: 95%;
    }
  }

  .logo {
    text-transform: uppercase;
    justify-self: start;
    line-height: normal;
    @include dela();
    font-size: 30px;
    font-weight: 400;

    letter-spacing: 0;
    text-align: left;
    padding-bottom: 12px;
    @include tablet {
      font-size: 15px;
      padding: 0;
    }
  }
  .titles {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: fit-content;
    padding: 24px 0;
    gap: 48px;
    @include laptop {
      gap: 30px;
    }
    @include tablet {
      gap: 15px;
    }
    @include mobile {
      width: 100%;
      align-items: start;
      flex-direction: column;
      gap: 0;
      padding: 0;
      .line {
        background: black;
        width: 100%;
        height: 1px;
      }
      a {
        @include mobile {
          padding: 14px;
          width: 100%;
          text-transform: uppercase;


        }
      }
    }
    h3 {
      color: #262626;
      @include dela;
      @include hoveredText;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      text-transform: uppercase;
      &:hover {
        background-size: 100% 3px;
      }
      @include tablet {
        font-size: 12px;
      }
      @include mobile {
        width: fit-content;
        font-size: 20px;
      }
    }

  }
  .social {
    justify-self: end;
    display: flex;
    align-items: end;
    padding: 19px 20px;
    gap: 20px;
    height: fit-content;
    a {
      @include hoverButton();
    }
    @include tablet {
      svg {
        width: 20px;
      }
    }
  }

  .visible {
    width: 100%;
    display: flex;
    justify-content: space-between;

    padding: 14px;
    .logo {
      padding: 0 !important;
      line-height: 54.3%;
      font-size: 16px;
    }
  }
}

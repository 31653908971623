@import "src/styles/global";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  gap: 80px;
  @include mobile {
    margin-top: 11.54vw;
    gap: 5.13vw;
  }
  h1 {
    @include dela;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    @include tablet {
      font-size: 7.18vw;
      line-height: 7.18vw;
    }
  }
  p {
    @include mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include mobile {
      font-size: 3.85vw;
    }
  }
  b {
    font-weight: 700;
  }
  .block1 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    @include tablet {
      gap: 3.85vw;
    }
    &::after {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      right: -30px;
      top: 70px;
      width: 588px;
      height: 55px;
      background-image: url("../../assets/blots/text3.png");
      z-index: -1;
      @include mobile {
        display: none;
      }
    }
    &_wrapper {
      display: flex;
      position: relative;
      .text {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 45%;
        @include tablet {
          gap: 3.85vw;
          width: 80%;
        }
      }
      img {
        position: absolute;
        right: 5%;
        @include tablet {
          display: none;
        }
      }
    }
  }
  .block2 {
    position: relative;
    &::after {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      left: -300px;
      top: -150px;
      width: 762px;
      height: 360px;
      background-image: url("../../assets/blots/bg5.png");
      z-index: -1;
      transform: rotate(3.852deg);
      @include tablet {
        top: -300px;
        left: -100px;
        transform: scale(0.8);
      }
    }
    .tags {
      display: block;
      position: relative;
      width: 100%;
      height: 650px;
      @include tablet {
        height: 450px;
      }
      .tag1 {
        max-width: 35%;
        position: absolute;
        top: 25%;
        left: 15%;
        @include tablet {
          top: 10%;
          max-width: 60vw;
        }
        &::after {
          pointer-events: none;
          content: "";
          display: block;
          position: absolute;
          left: -200px;
          top: -80px;
          width: 321px;
          height: 220px;
          background-image: url("../../assets/blots/text4.png");
          z-index: -1;
          transform: rotate(3.852deg);
          @include tablet {
            left: -150px;
            top: -60px;
            transform: scale(0.6);
          }
        }
      }
      .tag2 {
        max-width: 30%;
        position: absolute;
        top: 35%;
        right: 5%;
        @include tablet {
          top: 40%;
          right: 30%;
          max-width: 40vw;
        }
        &::after {
          pointer-events: none;
          content: "";
          display: block;
          position: absolute;
          left: -200px;
          top: -80px;
          width: 320px;
          height: 213px;
          background-image: url("../../assets/blots/text5.png");
          z-index: -1;
          transform: rotate(3.852deg);
          @include tablet {
            left: 30px;
            top: -80px;
            transform: scale(0.6);
          }
        }
      }
      .tag3 {
        max-width: 35%;
        position: absolute;
        top: 70%;
        left: 45%;
        @include tablet {
          top: 65%;
          left: 30%;
          max-width: 55.5vw;
        }
        &::after {
          pointer-events: none;
          content: "";
          display: block;
          position: absolute;
          left: -230px;
          top: -60px;
          width: 306px;
          height: 210px;
          background-image: url("../../assets/blots/text6.png");
          z-index: -1;
          transform: rotate(3.852deg);
          @include tablet {
            left: -200px;
            transform: scale(0.6);
          }
        }
      }
    }
  }
}
@import "~normalize.css";
@import "./variables.scss";

*,
h1,
h2,
h3,
p,
span,
a,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

span,
img,
a,
button,
div,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
b,
p,
input,
textarea {
  font-weight: inherit;

  &::selection {
    color: #262626;
    background-color: #20bcd8;
  }
}

body {
  @include bebas;

  position: relative;
  min-height: 100vh;
  overflow: hidden;
  font-size: 18px;
  line-height: normal;
  background-color: #eae9e4;
  color: #262626;
  padding-right: 0 !important;
  @include laptop {
    font-size: 1.5vw;
  }
  @include mobile {
    // border: 2px solid red;
  }
}

button {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s;

  &:active {
    opacity: 0.6;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style-position: inside;
}

ul {
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  overflow-x: hidden;
}

// --- libs ---
.slider {
  overflow: visible !important;

  & > div:first-child {
    & > div {
      width: fit-content;
      @include mobile {
        max-width: 90%;
      }
    }
  }

  &-indent {
    margin-left: -69px !important;
    @include laptop {
      margin-left: 0 !important;
    }
  }
}

#label {
  //position: absolute;
  width: 1104px;
  height: 537px;

  color: black;
  display: flex;
  justify-content: space-between;
  gap: 34px;
  margin-bottom: 20px;
  align-items: flex-end;
  color: black !important;
  @include tablet {
    gap: 20px;
  }

  &::after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 40px;
    //border: 1px red solid;
    width: 50%;
    height: 60px;
    background: linear-gradient(rgba(0, 0, 0, 0), #eae9e4);
    z-index: 2;
    @include tablet {
      bottom: 0;
      width: 100%;
    }
  }
  @include laptop {
    width: 100%;
  }
  @include tablet {
    flex-direction: column-reverse;
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
  }

  #left {
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 30px;
    //display: flex;
    //flex-direction: column;
    //gap: 1px;
    position: relative;

    @include tablet {
      width: 100%;
    }
    h1 {
      @include mulish;
      font-size: 30px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 33px */
      @include tablet {
        font-size: 4.62vw !important;
      }
    }
    h2 {
      @include mulish;
      font-size: 30px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      margin-bottom: 26px;
      @include tablet {
        margin-bottom: 6.67vw;
        font-size: 4.62vw !important;
      }
    }
    p {
      @include mulish;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 27.5px */
      padding-bottom: 15px;
      @include tablet {
        font-size: 3.85vw;
      }
    }
    h3 {
      @include mulish;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 10px;
      @include tablet {
        margin-bottom: 5px;
        font-size: 3.85vw;
      }
    }
  }
}

.MuiDialog-paper {
  max-width: 100% !important;
  border-radius: 15px !important;
  background: #eae9e4 !important;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25) !important;
  #scroll-dialog-title {
    padding-right: 20px !important;
  }
  & > .MuiDialogContent-root {
    border-radius: 15px !important;
  }
}

#scroll-dialog-title {
  padding-bottom: 0;
  text-align: end !important;
}

#team1 {
  &::after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    left: -60px;
    top: 0px;
    width: 260px;
    height: 250px;
    background-image: url("../assets/blots/bg10.png");
    z-index: -1;
    transform-origin: center;
    transform: scale(1);
    @include tablet {
      left: -30%;
    }
    @include mobile {
      z-index: -1;
      bottom: -205px;
      left: 30%;
      transform: scale(1);
      background: url("../assets/blots/bg10.png") center center no-repeat;
    }
  }
}

#team2 {
  &::after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    left: 10px;
    bottom: -115px;
    width: 454px;
    height: 501px;
    background-image: url("../assets/blots/bg11.png");
    z-index: 0;
    transform-origin: center;
    transform: scale(-0.5, 0.5);
    @include mobile {
      z-index: -1;
      bottom: -135px;
      left: -180px;
      transform: scale(1);
      background: url("../assets/blots/bg11.png") center center no-repeat;
    }
  }
}
#team3 {
  &::after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    right: -260px;
    bottom: -135px;
    width: 509px;
    height: 487px;
    background-image: url("../assets/blots/bg12.png");
    z-index: 0;
    transform-origin: center;
    transform: scale(0.5);
    @include mobile {
      z-index: -1;
      bottom: -205px;
      right: -200px;
      transform: scale(1);
      background: url("../assets/blots/bg12.png") center center no-repeat;
    }
  }
}

@import "src/styles/global";

.projectWrapper {
  width: 269px;
  height: 180px;
  transition: 0.3s;
  position: relative;
  border-radius: 15px;
  @include tablet {
    flex: 0 0 auto;
  }
  @include mobile {
    width: 80vw;
    height: 80vw;
  }

  p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    border-radius: 14px;

    @include mulish;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 9;
    transition: 0.3s;
    @include mobile {
      position: relative;
      background: none;
      color: black !important;
      font-size: 3.85vw !important;

    }
  }
  b {
    font-size: 20px !important;
    font-weight: 700;
    @include mobile {
      font-size: 3.85vw !important;
    }
  }
  img {
    position: absolute;
    width: 101%;
    height: auto;
    transition: 0.3s;

    bottom: 0;
    z-index: 0;
    @include mobile {
      position: relative;
      border-radius: 15px;

    }
  }
  video {
    position: relative;
    width: 50%;
    height: auto;
    transition: 0.3s;

    bottom: 0;
    z-index: 0;
    @include mobile {
      position: relative;
      border-radius: 15px;

    }
  }

  .text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    p {
      @include mobile {
        padding-left: 0;
      }
    }
    button {
      margin: 10px;
      margin-right: 0;
      border-radius: 5vw;
      height: 10vw;
      border: 2px solid #000;
      p {
        padding-left: 10px;
      }
    }
  }


}



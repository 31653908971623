@import "styles/variables.scss";

.slider {
  margin: 0;
  max-width: 603px;
  padding-bottom: 75px;
  border-radius: 20px;
 
  @include laptop {
    max-width: 41vw;
  }

  @include tablet {
    padding-bottom: 58px;
    max-width: 100%;
  }

  & .slide {
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    & img {
      border-radius: 22px;
      object-position: center center;
      width: 100%;
    }
    @include tablet {
      width: 100%;
      height: 45vw;
    }
  }
  & > div {
    // navigation buttons
    &:nth-child(2),
    &:nth-child(3) {
      bottom: 0;
      top: initial;
      width: 54px;
      height: 54px;
      background-image: url("/assets/tabs/sliders/arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      @include tablet {
        width: 36px;
        height: 36px;
      }
      &::after {
        display: none;
      }
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
    &:nth-child(2) {
      left: 50%;
      transform: translateX(-150%);
    }
    &:nth-child(3) {
      right: 50%;
      transform: translateX(150%) scale(-1, 1);
    }
  }
}

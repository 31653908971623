@import "styles/variables.scss";
.wr {
  // margin-bottom: 126px;
  // @include mobile {
  //   margin-bottom: 40px;
  // }
}

.name {
  color: #262626;
  font-size: 40px;
  @include dela;
  margin: 100px 0 40px;
  position: relative;
  width: fit-content;
  text-transform: uppercase;
  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @include mobile {
      display: none;
    }
  }
  @include mobile {
    font-size: 26px;
    margin: 40px 0 15px;
  }

  &_lect {
    margin-top: 35px;
    &::before {
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      width: 293px;
      height: 100px;
      background-image: url("/assets/program/lect.png");
    }
  }
  &_mk {
    &::before {
      left: 5%;
      bottom: -30px;
      width: 95%;
      height: 44px;
      transform: rotate(2deg);
      background-image: url("/assets/program/mk.png");
    }
  }
  &_exc {
    &::before {
      left: 50%;
      top: 25%;
      transform: translate(-50%, -50%);
      width: 413px;
      height: 128px;
      background-image: url("/assets/program/exc.png");
    }
  }
  &_perf {
    &::before {
      left: -10%;
      bottom: -20px;
      width: 110%;
      height: 31px;
      background-image: url("/assets/program/perf.png");
    }
  }
}

.paint {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: -1;
  }

  &_exc {
    &:before {
      mix-blend-mode: darken;
      background-image: url("/assets/paint-yellow.png");
      top: 0;
      right: -20%;
      width: 750px;
      height: 1093px;

      @include mobile {
        display: none;
      }
    }
  }
}

.prev,
.next {
  z-index: 1;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include laptop {
    display: none;
  }

  @include tablet {
    width: 36px;
    height: 36px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 384px;
    height: 339px;
    top: 50%;
    left: -40%;
    transform: translate(-50%, -50%);
    background: linear-gradient(
      270deg,
      rgba(234, 233, 228, 0) 6.59%,
      #eae9e4 35.14%
    );
    z-index: -2;
  }

  // &:hover {
  //   opacity: 0.7;
  // }
  &:active {
    opacity: 1;
  }
}

.prev {
  left: calc(630px - 48vw);
}
.next {
  left: calc(470px + 48vw);
  transform: scale(-1, 1);
}

@import "styles/variables.scss";

.wr {
  background-color: #262626;
  position: relative;
  &>div {
    height: auto;
  }
  &::before,
  &::after {
    z-index: -1;
    width: 100%;
    height: 441px;
    left: 0;
    position: absolute;
    content: "";
    background-image: url("/assets/tabs/top.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @include mobile {
      background-size: 160% 80%;
    }
  }
  &::before {
    top: 0;
    transform: translateY(-35%);
    @include mobile {
      transform: translateY(-25%);
    }
  }
  &::after {
    bottom: 0;
    transform: translateY(35%) scale(-1, -1);
    @include mobile {
      transform: translateY(25%) scale(-1, -1);
    }
  }
}
.container {
  margin: 220px 0;
  padding: 0 44px;
  display: flex;
  justify-content: space-between;
  @include laptop {
    padding: 0 5vw;
    gap: 20px;
  }
  @include tablet {
    gap: 30px;
    margin: 20vw 0;
    flex-direction: column;
  }
}
.list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  @include tablet {
    display: grid;
    gap: 29px 63px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-content: center;
  }
  &_item {
    color: #fff;
    @include dela;
    font-size: 35px;
    line-height: 110%; /* 38.5px */
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: left;
    @include tablet {
      align-items: center;
      text-align: center;
      font-size: 22px;
    }
    &:first-child {
      & > span {
        &:first-child {
          width: 85%;
          position: relative;
          padding-left: 26px;
          @include tablet {
            padding-left: 19px;
            width: 50%;
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 100%;
            background-image: url("/assets/tabs/more.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            @include tablet {
              width: 15px;
            }
          }
        }
      }
    }
    & > span {
      border-bottom: 3px solid transparent;
      transition: border-color 0.3s;
      &:first-child {
        width: 35%;
      }
      &:last-child {
        font-size: 29px;
        @include tablet {
          font-size: 15px;
          letter-spacing: -0.3px;
        }
      }
    }
    &:hover {
      & > span {
        border-color: #fff;
      }
    }
    &__active {
      & > span {
        border-bottom: 3px solid #fff;
      }
    }
  }
}

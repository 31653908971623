@import "styles/variables.scss";

.wr {
  color: #fff;
  // margin: 500px 0;
  margin-bottom: 200px;
  background-color: #262626;
  padding: 30px 0;
  position: relative;
  &::before,
  &::after {
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    content: "";
    background-image: url("/assets/merch/top.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @include mobile {
      background-size: 160% 80%;
    }
  }
  &::before {
    top: 0;
    transform: translateY(-35%);
    @include mobile {
      transform: translateY(-25%);
    }
  }
  &::after {
    bottom: 0;
    transform: translateY(35%) scale(-1, -1);
    @include mobile {
      transform: translateY(25%) scale(-1, -1);
    }
  }
}

.container {
  & > header {
    display: flex;
    gap: 62px;
    align-items: center;
    margin-bottom: 17px;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 15px;
    }
    & > h2 {
      @include dela;
      font-size: 60px;
      text-transform: uppercase;
      @include mobile {
        font-size: 28px;
      }
    }
    & > p {
      color: #fff;
      @include mulishBold;
      font-size: 20px;
      line-height: 110%;
      @include mobile {
        @include mulish;
        font-size: 15px;
        & > span {
          @include mulishBold;
        }
      }
    }
  }
  & > main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    padding-left: 2%;
    margin: 0 auto 10px;
    @include laptop {
      max-width: 90%;
    }
    @include tablet {
      gap: 20px;
    }
    @include mobile {
      grid-template-columns: repeat(2, 1fr);
      padding-left: 9%;
      gap: 0 20px;
      max-width: 100%;
      margin-bottom: 20px;
    }
    & > img {
      width: 100%;
      @include mobile {
        width: auto;
      }
    }
  }
}

.m_1 {
  max-width: 266px;
  justify-self: start;
  @include mobile {
    max-width: 182px;
    transform: rotate(10deg);
  }
}
.m_2 {
  max-width: 343px;
  transform: translateX(-8%);
  @include tablet {
    transform: none;
  }
  @include mobile {
    max-width: 310px;
    order: 1;
    grid-column: span 2;
    justify-self: center;
    transform: translateX(-10%);
  }
}
.m_3 {
  transform: rotate(6.6deg);
  max-width: 300px;
  justify-self: end;
  @include mobile {
    max-width: 197px;
    transform: rotate(-5deg) translate(-30%, -10%);
  }
}

.link {
  margin: 0 auto;
  padding: 10px 27px;
  color: #000;
  @include mulishBold;
  text-align: center;
  font-size: 34px;
  font-weight: 1000;
  border-radius: 65px;
  background-color: #ffc90a;
  transform: rotate(-2deg);
  transition: transform 0.3s;
  display: block;
  width: fit-content;
  &:hover {
    transform: rotate(-2deg) scale(1.05);
  }
  @include mobile {
    transform: rotate(-5deg);
    font-size: 24px;
    padding: 2px 17px 4px;
  }
}

@import "src/styles/global";

.wrapper {
  margin-top: 130px;
  margin-bottom: 230px;
  position: relative;
  @include tablet {
    margin-bottom: 18vw;
  }
  &::before {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    right: -530px;
    top: 330px;
    width: 1001px;
    height: 915px;
    background-image: url("../../assets/blots/bg14.png");
    z-index: -1;
    @include tablet {
      right: 50px;
      top: 200px;
      width: 489px;
      height: 46px;
      background-image: url("../../assets/blots/bg15.png");
    }
  }
  &::after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    left: -230px;
    bottom: -430px;
    width: 695px;
    height: 815px;
    background-image: url("../../assets/blots/bg9.png");
    z-index: -1;
    @include tablet {
      width: 824px;
      height: 943px;
      bottom: -280px;
      background-image: url("../../assets/blots/bg13.png");
      left: -400px;
    }
  }
  h1 {
    @include dela;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 72px */
    text-transform: uppercase;
    @include tablet {
      font-size: 7.18vw;
    }
  }
  h2 {
    @include dela;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 36px */
    letter-spacing: -0.9px;
    text-transform: uppercase;
  }
  h3 {
    @include mulish;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 36px */
    @include tablet {
      font-size: 4.62vw;
    }
  }
  p {
    @include mulish;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 27.5px */
    @include tablet {
      font-size: 3.85vw;
    }
  }
  b {
    @include mulish;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    @include tablet {
      font-size: 3.85vw;
    }
  }
  .row1 {
    display: flex;
    margin-bottom: 225px;
    @include tablet {
      margin-bottom: 30px;
      flex-direction: column;
    }
    h1 {
      width: 100%;
      line-height: 70%;
      @include tablet {
        margin-bottom: 3.85vw;
      }
    }
    p {
      width: 100%;
    }
  }
  .row2 {
    display: flex;
    align-items: flex-end;
    margin-bottom: 25px;
    h3, {
      width: 100%;
    }
    &_wrapper {
      width: 100%;
      height: 72px;
      position: relative;
      @include tablet {
        display: none;
      }
    }
    h2 {
      position: absolute;
      bottom: 50%;
      left: 40%;
      transform: rotate(4.504deg);
      &::after {
        pointer-events: none;
        content: "";
        display: block;
        position: absolute;
        left: -30px;
        top: -30px;
        width: 342px;
        height: 160px;
        background-image: url("../../assets/blots/text8.png");
        z-index: -1;
      }
    }
  }
  .row3 {
    display: flex;
    gap: 50px;
    position: relative;
    margin-top: 200px;
    margin-bottom: 200px;
    @include mobile {
      gap: 20px;
    }
    &::after {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      left: -300px;
      top: -300px;
      width: 722px;
      height: 646px;
      background-image: url("../../assets/blots/bg3.png");
      z-index: -1;
      @include mobile {
        transform: rotate(90deg) scale(0.6);
        left: 0;
        right: -300px;
        top: -400px;
      }
    }
    @include tablet {
      flex-direction: column;
      margin-top: 60px;
      margin-bottom: 60px;
    }

    .buttons {
      position: relative;
      height: 100%;
      //border: 2px red solid;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      justify-items: center;
      @include mobile {
        display: none;
      }
      a {
        position: relative;
        transition: 0.5s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    video {
      max-width: 40vw;
      transform: rotate(1.856deg);
      border-radius: 20px;
      @include mobile {
        max-width: 100%;
        width: 85vw;
        margin-left: 5vw;
        transform: rotate(-4.079deg);
      }
    }
    span {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
  .projects {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 28px;
    margin-top: 28px;

    @include tablet {
      width: 100vw;
      margin-left: -2.5%;
      padding-left: 2.5vw;
      padding-right: 2.5vw;
      -webkit-overflow-scrolling: touch;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
    }
  }
}
@import "styles/variables.scss";

.arrow {
  width: 3vw;
  height: 3vw;
  border-radius: 200px;
  position: fixed;
  bottom: 10vh;
  right: 50px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  z-index: 999;
  opacity: 1;
  transition: opacity 0.3s, box-shadow 0.3s ease, transform 0.3s ease;
  backdrop-filter: blur(3px);
  @include mobile {
    display: none;
  }
  & > svg {
    border-radius: 200px;
    width: 100%;
    height: 100%;
  }

  &:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease;
  }
  &:focus {
    opacity: unset;
  }

  &_hidden {
    opacity: 0 !important;
    pointer-events: none;
    transition: opacity 0.3s;
  }
}

//--------- MEDIA ---------

@media (max-width: 770px) {
  .arrow {
    border-radius: 200px;
    border: none;
    position: fixed;
    bottom: 10vh;
    right: 30px;
  }
}

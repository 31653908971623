@import "src/styles/global";

.team {
  display: flex;

  gap: 20px;
  width: fit-content;
  height: fit-content;
  position: relative;
  align-items: center;
  img {
    width: 185px;
    height: 185px;
  }
  h1 {
    @include mulish;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 25px */
    text-transform: none;
    margin-bottom: 10px;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    @include mobile {
      align-items: start;
    }
  }
  .open {
    position: absolute;
    display: inline-flex;
    padding: 27.523px 40.088px 63.273px 25.802px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12.901px;
    background: #EAE9E4;
    gap: 20px;

    box-shadow: 0px 3.44034px 8.60086px 0px rgba(0, 0, 0, 0.25);
    z-index: 3;
    transition: 0.5s;
    li {
      @include mulish;
      text-align: left;
      font-size: 21.502px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 21.502px */
      letter-spacing: -0.43px;
      white-space: nowrap;
    }
  }
  .close {
    position: absolute;
    display: inline-flex;

    padding: 0;
    gap: 0;
    height: 0;

    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12.901px;
    background: #EAE9E4;


    box-shadow: 0px 3.44034px 8.60086px 0px rgba(0, 0, 0, 0.25);
    z-index: 3;

    transition: 0.5s;
    li {
      @include mulish;
      text-align: left;
      font-size: 21.502px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 21.502px */
      letter-spacing: -0.43px;
      white-space: nowrap;
    }
  }


}
// --- FONTS ---

@font-face {
  font-family: "Bebas Neue Cyrillic Regular";
  src: local("Bebas Neue Cyrillic Regular"),
    url("./fonts/bebasneuecyrillic.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Dela Gothic One Regular";
  src: local("Dela Gothic One Regular"),
    url("./fonts/DelaGothicOne-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@mixin bebas {
  font-family: "Bebas Neue Cyrillic Regular", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin dela {
  font-family: "Dela Gothic One Regular", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin inter {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin interSemi {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin mulish {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin mulishSemi {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin mulishBold {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin mulishExtraBold {
  font-family: "Mulish", sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin hoverButton {
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
@mixin hoverLink {
  transition: color 0.3s;
  &:hover {
    color: #20bcd8;
  }
}
@mixin hoverLinkWhite {
  transition: color 0.3s;
  &:hover {
    color: #ffffff;
  }
}

@mixin hoveredText {
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1.5px;
  transition: background-size 0.5s;
}

// --- BREAKPOINTS ---

$desktop-width: 1500px;
$laptop-width: 900px;
$mobile-width: 520px;

@mixin laptop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$laptop-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin ellipse {
  position: relative;
  z-index: 1;
  & > svg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    z-index: 0;
  }
}

@import "styles/variables.scss";

.wr {
  // padding: 80px 0;
  margin-bottom: 140px;
  @include mobile {
    margin-bottom: 36px;
  }
  position: relative;
  &:before {
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: -1;
    background-image: url("/assets/program/line.png");
    top: -3%;
    right: 7%;
    width: 642px;
    height: 842px;
    @include mobile {
      background-image: url("/assets/program/line-mobile.png");
      width: 690px;
      height: 496px;
      transform: rotate(-71deg);
      top: 45%;
      right: -40%;
    }
  }

  @include mobile {
    & > h2 {
      & > svg {
        margin-left: 8px;
        width: 16px;
        height: 19px;
        transform: translateY(2px);
      }
    }
  }
}

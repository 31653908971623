@import "styles/variables.scss";

.card {
  width: 451px;
  height: 273px;
  padding: 15px 16px 16px 19px;
  border-radius: 15px;
  border: 2px solid #262626;
  background-color: #eae9e4;
  // backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mobile {
    width: 100%;
    height: auto;
    min-height: 173px;
    // min-height: 193px;
    gap: 18px;
    padding: 13px 11px 15px 14px;
    border-radius: 11px;
    border: 1.5px solid #262626;
  }
  & > .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include bebas;
    & > div {
      display: flex;
      gap: 10px;
      align-items: flex-end;
      color: #262626;
      & .date {
        font-size: 40px;
        letter-spacing: -0.25px;
        @include dela;

        @include mobile {
          font-size: 28px;
          letter-spacing: -0.84px;
        }
      }
      & .time {
        font-size: 20px;
        letter-spacing: -0.2px;
        padding-bottom: 2%;
        @include dela;
        @include mobile {
          padding-bottom: 4%;
          font-size: 15px;
          letter-spacing: -0.15px;
        }
      }
    }
    & > a {
      @include hoverButton;
      padding: 11px 22px 14px 18px;
      // padding: 15px 18px 12px 17px;
      border-radius: 30px;
      background-color: #20bcd8;
      color: #262626;
      font-size: 20px;
      line-height: normal;
      &::first-letter {
        text-transform: uppercase;
      }
      @include mulishBold;
      @include mobile {
        padding: 8px 16px 10px 13px;
        border-radius: 21px;
        font-size: 14px;
      }
    }
    & > .inactive {
      background-color: #d7d7d7 !important;
      color: #000 !important;
      pointer-events: none;
    }
  }
  & > main {
    @include mulishBold;
    & > h4 {
      font-size: 22px;
      line-height: 100%;
      margin-bottom: 6px;
      @include mobile {
        font-size: 16px;
      }
    }
    & > span {
      font-size: 18px;
      line-height: normal;
      margin-bottom: 5px;
      display: block;
      @include mobile {
        font-size: 13.5px;
      }
    }
    & > p {
      font-size: 16px;
      @include mulish;
      line-height: normal;
      // text-transform: lowercase;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -moz-box-orient: vertical;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      @include mobile {
        font-size: 12px;
      }
    }
  }
}

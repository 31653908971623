@import "styles/variables.scss";

.title {
  color: #262626;
  @include dela;
  font-size: 60px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  @include laptop {
    font-size: 45px;
  }
  @include mobile {
    font-size: 28px;
  }
  & > svg {
    margin-left: 26px;
    transform: translateY(5px);
  }
}
